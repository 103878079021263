import React, { useEffect, useState } from 'react'
import logoPTM from "../../../../images/newLogoPTM.jpeg"
import "./NotificacionesProf.css"
import { useNavigate } from 'react-router-dom'
import { SessionExpired } from '../../../components/SessionExpired/SessionExpired'
import { notifProfServices } from './services/notifProf.service'
import { useForm } from 'react-hook-form'
import useIp from '../IniciarSesion/hooks/useIp.js'
import { createEvento } from '../../../../services/evento.service.js'

const NotificacionesProf = ({
    setEstaEnGestionNotif,
    usuarioEnSesion,
    token,
    setToken,
    setUsuarioEnSesion,
    setEstaEnSesion,
    setEstaEnAjustes,
    setEstaEnPregFrec,
    setEstaEnContacto
}) => {
    const navigate = useNavigate()
    const [tokenVencido, setTokenVencido] = useState(false)
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const ip = useIp()

    // modal de desactivacion
    const [showModalDesac, setShowModalDesac] = useState(false);
    const handleOpenModalDesac = () => {
        setShowModalDesac(true);
    };
    const handleCloseModalDesac = () => {
        setShowModalDesac(false);
    };

    // modal de activacion
    const [showModalAct, setShowModalAct] = useState(false);
    const handleOpenModalAct = () => {
        setShowModalAct(true);
    };
    const handleCloseModalAct = () => {
        setShowModalAct(false);
    };

    const crearE = async (data, token) => {
        await createEvento(data, token)
    }

    useEffect(() => {
        if (ip !== null) {
            crearE({ id_tipo: 33, id_resultado: 1, ip_user: ip }, token)
        }
    }, [ip])

    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const determinarModal = () => {
        if (usuarioEnSesion.NotifActivadas === 0) {
            handleOpenModalAct()
            crearE({ id_tipo: 35, id_resultado: 1, ip_user: ip }, token)
        } else {
            handleOpenModalDesac()
            crearE({ id_tipo: 34, id_resultado: 1, ip_user: ip }, token)
        }
    }

    const onSubmit = async (data) => {
        data.cantHs = data.cantHs ? parseInt(data.cantHs) : null
        data.notifActivadas = usuarioEnSesion.NotifActivadas === 1 ? 0 : 1
        data.fechaHoraDesact = usuarioEnSesion.NotifActivadas === 1 ? new Date() : null
        data.ip = ip
        if (data.fechaHoraDesact !== null) {
            let date = data.fechaHoraDesact;

            // Restar 3 horas para ajustar a UTC-3
            date.setHours(date.getHours() - 3);

            let year = date.getFullYear();
            let month = String(date.getMonth() + 1).padStart(2, '0');
            let day = String(date.getDate()).padStart(2, '0');
            let hours = String(date.getHours()).padStart(2, '0');
            let minutes = String(date.getMinutes()).padStart(2, '0');
            let seconds = String(date.getSeconds()).padStart(2, '0');

            let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            data.fechaHoraDesact = formattedDate;
        } else {
            data.fechaHoraDesact = null;
        }

        const response = await notifProfServices.gestNotif(data, token)
        if (response.error === "TOKEN VENCIDO.") {
            setTokenVencido(true);
            return;
        }
        setUsuarioEnSesion(response)
        window.localStorage.setItem("DoctorFy", JSON.stringify(response))
        handleCloseModalAct()
        handleCloseModalDesac()
        setEstaEnGestionNotif(false)
        navigate("/bienvenida")
    }


    const handleBack = () => {
        setEstaEnGestionNotif(false)
        navigate(-1)
        crearE({ id_tipo: 123, id_resultado: 1, ip_user: ip }, token)
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="row">
                        <div className="col s12 m6 offset-m3">
                            <div className="logo-container center">
                                <img src={logoPTM} alt="Logo PTM" width="500" />
                            </div>
                            <h4 className="center-align" style={{ color: 'blue' }}>ACTIVAR/DESACTIVAR NOTIFICACIONES</h4>
                            {/* <h5 className="center-align" style={{ color: 'blue' }}>Sección Profesionales</h5> */}
                        </div >

                        <div className='col s12 center' style={{ marginTop: '24px' }}>
                            <button className='btn waves-effect waves-light' type='button' onClick={() => determinarModal()}>
                                {usuarioEnSesion?.NotifActivadas === 1 ? "DESACTIVAR" : "ACTIVAR"}
                            </button>
                        </div>

                        <div className='col s12 center' id='btn-close-sesion' style={{ marginTop: '24px' }}>
                            <button className='btn waves-effect waves-light' type='button' onClick={() => handleBack()}>
                                VOLVER
                            </button>
                        </div>
                    </div >
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}

            {showModalDesac && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="modal-close" onClick={() => handleCloseModalDesac()}>
                            <i
                                className='material-icons'
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                id="modal-close-icon"
                            >
                                close
                            </i>
                        </button>
                        <div className='col s12 center'>
                            <h5 id="header-modal">DESACTIVAR NOTIFICACIONES</h5>
                        </div>
                        <p id="description-modal">Si pulsás el botón OK vas a confirmar que querés desactivar tus notificaciones. Debés ingresar la cantidad de horas que querés que estén desactivadas.
                            Podés desactivar tus notificaciones entre 1 y 24 horas.</p>

                        <form className='col s12' onSubmit={handleSubmit(onSubmit)}>
                            <div className='col s12'>
                                <label htmlFor='cantHs'>Cantidad de horas</label>
                                <input
                                    type='number'
                                    id='cantHs'
                                    name='cantHs'
                                    className='cantHs'
                                    {...register('cantHs', {
                                        required: {
                                            value: true,
                                            message: 'Por favor, ingresá la cantidad de horas.'
                                        },
                                        max: {
                                            value: 24,
                                            message: 'No podés desactviar tus notificaciones por más de 24hs seguidas'
                                        },
                                        min: {
                                            value: 1,
                                            message: 'No podés desactviar tus notificaciones por menos de una hora'
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: 'Ingresá la cantidad de horas como un numero entero.'
                                        }
                                    })}
                                />
                                {errors.cantHs && <span className='error-message'>{errors.cantHs.message}</span>}
                            </div>

                            <div className='col s12 center'>
                                <button type='submit' className='btn waves-effect waves-light green darken-3' id="modal-button">
                                    OK
                                    <i className='material-icons right large'>done</i>
                                </button>
                            </div>
                        </form>


                    </div>
                </div>
            )}

            {showModalAct && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="modal-close" onClick={() => handleCloseModalAct()}>
                            <i
                                className='material-icons'
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                id="modal-close-icon"
                            >
                                close
                            </i>
                        </button>
                        <div className='col s12 center'>
                            <h5 id="header-modal">ACTIVAR NOTIFICACIONES</h5>
                        </div>
                        <p id="description-modal">Si pulsás el botón OK, vas a confirmar que querés reactivar tus notificaciones</p>
                        <form className='col s12' onSubmit={handleSubmit(onSubmit)}>
                            <div className='col s12 center'>
                                <button type='submit' className='btn waves-effect waves-light green darken-3' id="modal-button">
                                    OK
                                    <i className='material-icons right large'>done</i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}

export { NotificacionesProf }