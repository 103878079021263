import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFecha } from '../../hooks/useFecha';
import { SessionExpired } from '../../../components/SessionExpired/SessionExpired';
import logoPTM from "../../../../images/newLogoPTM.jpeg"
import { useForm } from 'react-hook-form';
import { transferenciaServices } from './services/transferencia.service';
import { solicTurnoProfServices } from '../../../SolicitudTurno/SolicitudTurnoMedico/services/solicTurnoProf.service';
import { Modal } from '../../../components/Modal/Modal';
import useIp from '../../../PantallaInicial/components/IniciarSesion/hooks/useIp';
import { useEvento } from '../../../../hooks/useEvento';


const RealizarTransferencia = ({
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
    showModal,
    handleOpenModal,
    handleCloseModal
}) => {

    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm()
    const [tokenVencido, setTokenVencido] = useState(false)
    const { id } = useParams()
    const [errorAlPagar, setErrorAlPagar] = useState(false)
    const [pulsoPagar, setPulsoPagar] = useState(false)
    const [entidades, setEntidades] = useState(null)

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    useEffect(() => {
        if (token) {
            const traerEntidadesFinancieras = async () => {
                const response = await transferenciaServices.getEntidades(token)
                if (response.error !== undefined) {
                    if (response.error === "TOKEN VENCIDO.") {
                        setTokenVencido(true);
                        return;
                    }
                }

                setEntidades(response)
            }
            traerEntidadesFinancieras()
        }
    }, [usuarioEnSesion])

    const handleKeyDown = (event, idCampoLlego) => {
        if (['+', '-', 'e', '.', ','].includes(event.key)) {
            event.preventDefault();
        }
        handleCampoLlego(idCampoLlego)
    };

    const handleBack = () => {
        navigate('/misOfertasTurnoPac')
        if (campoLlego !== null) {
            crearE({ id_tipo: 66, id_resultado: 2, ip_user: ip, id_form: 13, id_campo_llego: campoLlego }, token)
            crearE({ id_tipo: 67, id_resultado: 2, ip_user: ip, id_form: 13, id_campo_llego: campoLlego }, token)
        } else {
            crearE({ id_tipo: 67, id_resultado: 1, ip_user: ip }, token)
        }
    }

    const onSubmit = async (data) => {
        setPulsoPagar(true)

        // despues hacer lo del formData()

        data.data = {
            nroTransferencia: data.nroTransferencia,
            cbu_cvu_origen: data.cbu_cvu_origen,
            cbu_cvu_destino: data.cbu_cvu_destino,
            cuit_origen: data.cuit_origen,
            cuit_destino: data.cuit_destino,
            entidadFinanciera: data.entidadFinanciera,
            IdEstadoTurno: 4,
            medioPago: 1
        }

        data.file = data.comprobante[0]


        delete data.comprobante
        delete data.nroTransferencia
        delete data.cbu_cvu_origen
        delete data.cbu_cvu_destino
        delete data.cuit_origen
        delete data.cuit_destino
        delete data.entidadFinanciera

        const response = await solicTurnoProfServices.updateTurno(id, data, token, 3)

        if (response.error === "Error al realizar el pago. Por favor, revisá los datos y el monto en tu Tarjeta.") {
            setErrorAlPagar(true);
            setPulsoPagar(false)
            handleOpenModal();
            return;
        }

        if (response.error === "TOKEN VENCIDO.") {
            setTokenVencido(true);
            setPulsoPagar(false)
            return;
        }

        handleOpenModal();
        setPulsoPagar(false)
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-registro-container row">
                        <div className="row" style={{ marginBottom: 0 }}>
                            <div className='col s12 center'>
                                <div className="logo-container center">
                                    <img src={logoPTM} alt="Logo PTM" width="500" />
                                </div>
                                <h5 id="header-form-registro">INFORMAR PAGO</h5>
                            </div>
                        </div>

                        <form id="pago_form" className="row" onSubmit={handleSubmit(onSubmit)}>
                            <div className="col s12">

                                <div className='row'>
                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="nroTransferencia">Nro. Transferencia</label>
                                        <input id="nroTransferencia" onKeyDown={(e) => handleKeyDown(e, 74)} type="number" placeholder="*******************" name="nroTransferencia" style={{ fontWeight: "bold", marginBottom: "0px" }} className="validate input_p2" required {...register('nroTransferencia', {
                                            required: {
                                                value: true,
                                                message: "Número de transferencia es requerido."
                                            },
                                            pattern: {
                                                value: /^\d{10,18}$/,
                                                message: "Entre 10 y 18 dígitos."
                                            }
                                        })} />
                                        {errors.nroTransferencia && (
                                            <span className='error-message'>{errors.nroTransferencia.message}</span>
                                        )}
                                    </div>

                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="entidadFinanciera">Banco Destino</label>
                                        <select onChangeCapture={() => handleCampoLlego(77)} id="entidadFinanciera" name="entidadFinanciera" style={{ fontWeight: "bold", marginBottom: "1px" }} className="validate browser-default" required {...register('entidadFinanciera', {
                                            required: {
                                                value: true,
                                                message: "Banco destino es requerido."
                                            }
                                        })} >
                                            <option value="" disabled selected style={{ fontWeight: "bold" }}>Elegir</option>
                                            {entidades && entidades.map((entidad, index) => (
                                                <option key={index} style={{ fontWeight: "bold" }} value={entidad.id}>{entidad.nombre}</option>
                                            ))}
                                        </select>
                                    </div>

                                </div>

                                <div className='row'>
                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="cbu_cvu_origen">CBU/CVU Origen</label>
                                        <input id="cbu_cvu_origen" onKeyDown={(e) => handleKeyDown(e, 75)} type="number" placeholder="**********************" name="cbu_cvu_origen" style={{ fontWeight: "bold", marginBottom: "0px" }} className="validate input_p2" required {...register('cbu_cvu_origen', {
                                            required: {
                                                value: true,
                                                message: "CBU/CVU origen es requerido."
                                            },
                                            pattern: {
                                                value: /^\d{22}$/,
                                                message: "Exactamente 22 dígitos."
                                            }
                                        })} />
                                        {errors.cbu_cvu_origen && (
                                            <span className='error-message'>{errors.cbu_cvu_origen.message}</span>
                                        )}
                                    </div>

                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="cbu_cvu_destino">CBU/CVU Destino</label>
                                        <input id="cbu_cvu_destino" onKeyDown={(e) => handleKeyDown(e, 78)} type="number" placeholder="**********************" name="cbu_cvu_destino" style={{ fontWeight: "bold", marginBottom: "0px" }} className="validate input_p2" required {...register('cbu_cvu_destino', {
                                            required: {
                                                value: true,
                                                message: "CBU/CVU destino es requerido."
                                            },
                                            pattern: {
                                                value: /^\d{22}$/,
                                                message: "Exactamente 22 dígitos."
                                            }
                                        })} />
                                        {errors.cbu_cvu_destino && (
                                            <span className='error-message'>{errors.cbu_cvu_destino.message}</span>
                                        )}
                                    </div>

                                </div>

                                <div className='row'>
                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="cuit_origen">CUIT Origen</label>
                                        <input id="cuit_origen" onKeyDown={(e) => handleKeyDown(e, 76)} type="number" placeholder="***********" name="cuit_origen" style={{ fontWeight: "bold", marginBottom: "0px" }} className="validate input_p2" required {...register('cuit_origen', {
                                            required: {
                                                value: true,
                                                message: "CUIT origen es requerido."
                                            },
                                            pattern: {
                                                value: /^\d{11}$/,
                                                message: "Exactamente 11 dígitos."
                                            }
                                        })} />
                                        {errors.cuit_origen && (
                                            <span className='error-message'>{errors.cuit_origen.message}</span>
                                        )}
                                    </div>

                                    <div className="col s6">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="cuit_destino">CUIT Destino</label>
                                        <input id="cuit_destino" onKeyDown={(e) => handleKeyDown(e, 79)} type="number" placeholder="***********" name="cuit_destino" style={{ fontWeight: "bold", marginBottom: "0px" }} className="validate input_p2" required {...register('cuit_destino', {
                                            required: {
                                                value: true,
                                                message: "CUIT destino es requerido."
                                            },
                                            pattern: {
                                                value: /^\d{11}$/,
                                                message: "Exactamente 11 dígitos."
                                            }
                                        })} />
                                        {errors.cuit_destino && (
                                            <span className='error-message'>{errors.cuit_destino.message}</span>
                                        )}
                                    </div>

                                </div>

                                {/* ARCHIVO */}
                                <div className='row' style={{ marginBottom: "0" }}>
                                    <div className="col s12 center">
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }} htmlFor="comprobante">Comprobante de Transferencia</label>
                                        <input onChangeCapture={() => handleCampoLlego(80)} id="comprobante" type="file" name="comprobante" style={{ fontWeight: "bold", marginBottom: "0px" }} className="validate input_p2" accept=".jpeg, .jpg, .png" required {...register('comprobante', {
                                            required: {
                                                value: true,
                                                message: "Comprobante de transferencia es requerido."
                                            },
                                            validate: {
                                                acceptedFormats: (value) => {
                                                    const allowedFormats = ['image/jpeg', 'image/png', 'image/jpg'];
                                                    return allowedFormats.includes(value[0]?.type) || 'Formato de archivo no permitido, solo se permite .jpeg, .jpg, o .png';
                                                },
                                            },
                                        })} />
                                        {errors.comprobante && (
                                            <span className='error-message'>{errors.comprobante.message}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="col s12 center" style={{ marginBottom: "80px" }}>
                                    <button className='btn waves-effect waves-light' type="submit" style={{ margin: "8px" }} disabled={pulsoPagar}>
                                        INFORMAR PAGO
                                        <i className='material-icons right large'>account_box</i>
                                    </button>

                                    <button
                                        className='btn waves-effect waves-light'
                                        type="button"
                                        onClick={handleBack}
                                        style={{ margin: "8px" }}
                                    >
                                        VOLVER
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    {showModal && (
                        <Modal
                            title={errorAlPagar === true ? 'ERROR AL PAGAR' : 'PAGO REALIZADO CON ÉXITO'}
                            description={errorAlPagar === true ? "Error al realizar el pago. Por favor, revisá los datos y el monto en tu Tarjeta." : "Realizaste el pago de tu consulta con éxito. Revisá tu correo electrónico para ver el comprobante de pago."}
                            onClose={errorAlPagar === true ? () => { handleCloseModal(); crearE({ id_tipo: 69, id_resultado: 2, ip_user: ip, id_form: 13, id_campo_llego: campoLlego }, token); setErrorAlPagar(false) } : () => { handleCloseModal(); crearE({ id_tipo: 69, id_resultado: 1, ip_user: ip, id_form: 13, id_campo_llego: campoLlego }, token); navigate('/misOfertasTurnoPac'); setErrorAlPagar(false) }}
                        />
                    )}
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    )
}

export { RealizarTransferencia }