import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './OfertasTurnoPac.css';
import { useNavigate } from 'react-router-dom';
import { ofertasTurnosServices } from './traerofertas.service.js';
import logoPTM from '../../../images/newLogoPTM.jpeg'
import { SessionExpired } from '../../components/SessionExpired/SessionExpired.js';
import useIp from '../../PantallaInicial/components/IniciarSesion/hooks/useIp.js';
import { createEvento } from '../../../services/evento.service.js';
import { useEvento } from '../../../hooks/useEvento.js';

const OfertasTurnoPac = ({ usuarioEnSesion, token, setEstaEnSesion, setUsuarioEnSesion, setToken, setEstaEnAjustes, setEstaEnContacto, setEstaEnPregFrec }) => {

    const navigate = useNavigate();
    const [turnosOfrecidosPac, setTurnosOfrecidosPac] = useState([]);
    const [tokenVencido, setTokenVencido] = useState(false)

    const ip = useIp();
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerTurnosOfrecidosPaciente = async () => {
        if (usuarioEnSesion) {
            const response = await ofertasTurnosServices.getOfertasTurnoPac(usuarioEnSesion.usuarioComun.Id, token);
            if (response.error) {
                if (response.error === "TOKEN VENCIDO.") {
                    setTokenVencido(true);
                    return;
                }
            }
            setTurnosOfrecidosPac(response)
        }
    }

    // Traer los turnos que hayan sido asignados por un profesional, para un determinado paciente que esta en sesion
    useEffect(() => {
        traerTurnosOfrecidosPaciente()
    }, [usuarioEnSesion])

    const handleBack = () => {
        navigate('/bienvenida')
        crearE({ id_tipo: 124, id_resultado: 1, ip_user: ip }, token)
    }


    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className='col s12 center'>
                        <div className="logo-container center">
                            <img src={logoPTM} alt="Logo PTM" width="500" />
                        </div>
                        <h5 id="header-form-registro">MIS OFERTAS DE TURNO</h5>
                    </div>

                    <div className='col s12 center'>
                        <button
                            className='btn waves-effect waves-light'
                            type="button"
                            onClick={handleBack}
                            style={{ marginBottom: '8px' }}
                        >
                            VOLVER
                        </button>
                    </div>

                    <div className="col s12 center-align" id='tablaTurnos'>
                        {turnosOfrecidosPac.error === undefined ? (
                            <table className="striped responsive-table" style={{ marginBottom: '100px' }}>
                                <thead>
                                    <tr>
                                        <th>FECHA</th>
                                        <th>HORA</th>
                                        <th>ESPECIALIDAD</th>
                                        <th>ESTADO</th>
                                        <th>MODALIDAD</th>
                                        <th>OFERTA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {turnosOfrecidosPac.map((registro, index) => (
                                        <tr key={index}>
                                            <td style={{ fontWeight: "500" }}>{registro.Fecha}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Hora}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Especialidade.Nombre}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.EstadosTurno.Nombre}</td>
                                            <td style={{ fontWeight: "500" }}>{registro.Modalidad}</td>
                                            <td>
                                                <button className='btn waves-effect waves-light' onClick={() => navigate(`/ofertaTurno/${registro.IdTurno}`)} disabled={registro.IdEstadoTurno === 13}>
                                                    VER OFERTA
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <h2>NO HAY TURNOS</h2>
                        )}
                    </div>
                </>
            ) : (
                < SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
};

export { OfertasTurnoPac };