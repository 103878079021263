import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFecha } from '../../Turnos/hooks/useFecha';
import axios from 'axios';
import logoPTM from "../../../images/newLogoPTM.jpeg"
import './SolicitudTurnoPaciente.css'
import { useNavigate } from 'react-router-dom';
import { ciudadesService } from '../../Registro/services/ciudades.service.js';
import { Modal } from '../../components/Modal/Modal.js';
import { solicTurnoPacServices } from './services/solicTurnoPac.service.js';
import { SessionExpired } from '../../components/SessionExpired/SessionExpired.js';
import useIp from '../../PantallaInicial/components/IniciarSesion/hooks/useIp.js';
import { createEvento } from '../../../services/evento.service.js';
import { useEvento } from '../../../hooks/useEvento.js';


const SolicitudTurnoPaciente = (
    {
        apiUrl,
        usuarioEnSesion,
        token,
        setEstaEnSesion,
        setUsuarioEnSesion,
        setToken,
        setEstaEnAjustes,
        setEstaEnContacto,
        setEstaEnPregFrec,
        showModal,
        handleOpenModal,
        handleCloseModal
    }
) => {
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();
    const { fechaString, hora } = useFecha();

    // estos estados se usaran para mostrar por pantalla lo que haya indicado el usuairo, tal como solicita el requerimiento
    const [especilaidadElegida, setEspecialidadElegida] = useState('');
    const [ciudad, setCiudad] = useState('');
    const [modalidadElegida, setModalidadElegida] = useState(''); // al ser botones y no inputs de tipo radio, si o si debo almacenar la modalidadElegida en un estado y al valor de ese estado 'meterlo' dentro de los datos del formulario
    const [todasEspecialidades, setTodasEspecialidades] = useState([]);
    const [allCiudades, setAllCiudades] = useState([]);
    const [noHayMedicos, setNoHayMedicos] = useState(false) // por defecto si hay medicos
    const [tokenVencido, setTokenVencido] = useState(false)
    const [datosEspElegida, setDatosEspElegida] = useState(null)

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    useEffect(() => {
        if (ip !== null) {
            crearE({ id_tipo: 40, id_resultado: 1, ip_user: ip }, token)
        }
    }, [ip])

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    // traer todas las especialidades medicas desde el backend. SIN TOKEN
    useEffect(() => {
        const traerEspecialidades = async () => {
            if (usuarioEnSesion) {
                const especialidadesTraidas = await axios.get(`${apiUrl}/api/plataforma_medica/especialidades/disps`, { headers: { Authorization: `Bearer ${token}` } });
                setTodasEspecialidades(especialidadesTraidas.data)
            }
        };
        traerEspecialidades();
    }, [usuarioEnSesion]);

    const traerCiudades = async (idEspecialidad) => {
        const ciudadesTraidas = await ciudadesService.getCiudadesForTurnos(idEspecialidad);
        setAllCiudades(ciudadesTraidas)
    }

    useEffect(() => {
        const getCities = async () => {
            if (datosEspElegida) {
                await traerCiudades(datosEspElegida.Id)
            }
        }
        getCities()
    }, [datosEspElegida])

    // estados de validaciones
    const [eligioModalidad, setEligioModalidad] = useState(false);
    const [errorModalidad, setErrorModalidad] = useState(false);
    const [hizoClick, setHizoClick] = useState(false)


    // Actualizar el campo oculto con la modalidad seleccionada
    useEffect(() => {
        setValue('modalidad', modalidadElegida); // Actualizar el campo oculto con la modalidad seleccionada
    }, [modalidadElegida]);

    // en caso de que el usuario quiera cambiar algo, que se resetee todo el formulario
    const handleCambiar = () => {
        reset(); // Resetear el formulario y limpiar los campos registrados
        setEligioModalidad(false);
        setEspecialidadElegida('');
        setCiudad('');
        setModalidadElegida('');
        crearE({ id_tipo: 42, id_resultado: 1, ip_user: ip, id_form: 6, id_campo_llego: campoLlego }, token)
    };


    // en cuanto el usuario pulse solicitar turno, se ejecuta esta funcion, que es la que envia los datos del turno al backend para su creacion
    const onSubmit = async (data) => {
        setHizoClick(true)
        // si el usuario no indico una modalidad de atencion, no se ejecuta nada y devuelve error
        if (modalidadElegida === '') {
            setErrorModalidad(true);
            setHizoClick(false)
            return;
        }

        // ajustando el objeto data que es el que se va a enviar al backend para crear el turno
        data.fecha = fechaString; // Agregar la fecha al objeto data que es el que se va a enviar al backend
        data.hora = hora; // Agregar la hora al objeto data que es el que se va a enviar al backend
        // data.idPaciente = usuarioEnSesion.usuarioComun.Id // Agregar el Id de paciente que esta solicitando el turno
        data.especialidad = parseInt(data.especialidad) // Agregar la especialidad del turno
        data.ciudad = parseInt(data.ciudad)
        if (datosEspElegida) {
            if (datosEspElegida.esPsico) {
                data.esPsico = true
            }
        }

        const medicosPosibles = await solicTurnoPacServices.getProfsParaTurno(data, token)
        if (medicosPosibles.error) {
            if (medicosPosibles.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
        }

        if (medicosPosibles.noHay !== undefined) { // si no hay medicos de la misma ciudad y especialidad
            reset();
            setErrorModalidad(false);
            setEligioModalidad(false);
            setEspecialidadElegida('');
            setCiudad('');
            setModalidadElegida('');
            handleOpenModal()
            setNoHayMedicos(true)
            return;
        }

        setNoHayMedicos(false)
        data.interfaz = 1

        // creando turno
        const response = await solicTurnoPacServices.createTurnoPac(data, token)
        if (response.error) {
            if (response.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
        }

        // resetea los datos del formulario una vez ya enviados los datos, y tambien resetea los estados involucrados en el formulario
        reset();
        setErrorModalidad(false);
        setEligioModalidad(false);
        setEspecialidadElegida('');
        setCiudad('');
        setModalidadElegida('');
        handleOpenModal();
    };


    // funcion que se ejecuta si el usuario hace click en VOLVER
    const handleBack = () => {
        reset();
        setErrorModalidad(false);
        setEligioModalidad(false);
        setEspecialidadElegida('');
        setCiudad('');
        setModalidadElegida('');
        setTodasEspecialidades([]);
        setEstaEnSesion(true);
        setHizoClick(false)
        navigate("/bienvenida");
        if (campoLlego !== null && !hizoClick) {
            crearE({ id_tipo: 41, id_resultado: 2, ip_user: ip, id_form: 6, id_campo_llego: campoLlego }, token)
            crearE({ id_tipo: 43, id_resultado: 2, ip_user: ip, id_form: 6, id_campo_llego: campoLlego }, token)
        } else {
            crearE({ id_tipo: 43, id_resultado: 1, ip_user: ip }, token)
        }
    }


    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-registro-container row">
                        <form className="col s12" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className='col s12 center'>
                                    <div className="logo-container center">
                                        <img src={logoPTM} alt="Logo PTM" width="500" />
                                    </div>
                                    <h5 id="header-form-registro">SOLICITUD TURNO LADO PACIENTE</h5>
                                </div>
                                <div className="col s6">
                                    <label htmlFor="especialidad" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Especialidad requerida del turno</label>
                                    <select onChangeCapture={() => handleCampoLlego(45)} style={{ fontWeight: "bold" }} className="browser-default validate" id="especialidad" name="especialidad" defaultValue="" {...register('especialidad', { required: true })}
                                        onChange={(e) => {
                                            const especialidadSeleccionada = todasEspecialidades.find(
                                                (especialidad) => especialidad.Id === Number(e.target.value)
                                            );
                                            setEspecialidadElegida(especialidadSeleccionada?.Nombre || '');
                                            setDatosEspElegida(especialidadSeleccionada || null)
                                        }}
                                    >
                                        <option value="" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                        {todasEspecialidades.map((e, index) => {
                                            return (
                                                <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                            )
                                        })}
                                    </select>
                                    {errors.especialidad && <span className='error-message'>ERROR. Por favor elegí una especialidad para poder solicitar el turno</span>}
                                </div>

                                <div className="col s6">
                                    <label htmlFor="ciudad" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Donde queres tomar tu consulta?</label>
                                    <select onChangeCapture={() => handleCampoLlego(46)} disabled={!especilaidadElegida || !allCiudades || allCiudades.length === 0} style={{ fontWeight: "bold" }} className="browser-default validate" id="ciudad" name="ciudad" defaultValue="" {...register('ciudad', { required: true })} onChange={(e) => setCiudad(e.target.options[e.target.selectedIndex].text)}>
                                        <option value="" disabled style={{ fontWeight: "bold" }}>Elegí una opcion</option>
                                        {allCiudades.map((e, index) => (
                                            <option key={index} value={e.Id} style={{ fontWeight: "bold" }}>{e.Nombre}</option>
                                        ))}
                                    </select>
                                    {errors.ciudad && <span className='error-message'>ERROR. Por favor elegí un lugar de atención para poder solicitar el turno</span>}
                                </div>

                                <div className='col s12'>
                                    <label htmlFor="motivo" style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Motivo de consulta</label>
                                    <textarea onKeyDown={() => handleCampoLlego(47)} style={{ fontWeight: "bold" }} id="domicilio" name="domicilio" className='materialize-textarea validate' {...register('motivo', { required: true, minLength: 10, maxLength: 150 })} />
                                    {errors.motivo && <span className='error-message'>ERROR. Por favor ingresá un motivo válido para poder solicitar el turno</span>}
                                </div>


                                <div className='col s12'>
                                    <div className='col s4'>
                                        <label style={{ fontSize: "1.2rem", fontWeight: "bold", color: "black" }}>Modalidad de consulta</label>
                                    </div>
                                    <div className='pregunta-turno-container col s8'>
                                        <button type="button" onClick={() => { setModalidadElegida('presencial'); setEligioModalidad(true); setErrorModalidad(false); handleCampoLlego(48) }}
                                            className={`botonModalidad ${modalidadElegida === 'presencial' ? 'selected' : ''}`} id='presencial' >
                                            PRESENCIAL
                                        </button>

                                        <button
                                            type="button"
                                            onClick={() => { setModalidadElegida('virtual'); setEligioModalidad(true); setErrorModalidad(false); handleCampoLlego(48) }}
                                            className={`botonModalidad ${modalidadElegida === 'virtual' ? 'selected' : ''}`}
                                            id='virtual' >
                                            VIRTUAL
                                        </button>
                                    </div>
                                </div>
                                {errorModalidad && <span className='error-message'>ERROR. Por favor elegí una modalidad para poder solicitar el turno</span>}

                                {eligioModalidad && (
                                    <p className='text-center' id="texto-confirmacion-solicitud-turno">
                                        Vas a solicitar turno con un profesional de la salud
                                        especialista en {especilaidadElegida}, de la Ciudad de {ciudad}; en la modalidad {modalidadElegida},
                                        si no estás de acuerdo, pulsá el boton CAMBIAR.
                                    </p>
                                )}

                                <div className='col s12 center'>
                                    <button type='button' className='btn waves-effect waves-light' id="boton-cambio" onClick={handleCambiar}>
                                        Cambiar
                                    </button>

                                    <button
                                        className='btn waves-effect waves-light'
                                        type="button"
                                        onClick={handleBack}
                                        id="boton-cambio"
                                    >
                                        VOLVER
                                    </button>

                                    <button
                                        type="submit"
                                        className='btn waves-effect waves-light'
                                        id='boton-solicitar'
                                        style={{ margin: " 4px 4px 60px" }}
                                        disabled={hizoClick}
                                    >
                                        SOLICITAR TURNO
                                    </button>
                                </div>


                            </div>
                        </form>

                        {showModal && (
                            <Modal
                                title="TURNO SOLICITADO"
                                description="Ya solicitaste tu turno, pronto recibirás una oferta."
                                onClose={() => { handleCloseModal(); crearE({ id_tipo: 45, id_resultado: 1, ip_user: ip, id_form: 6, id_campo_llego: campoLlego }, token); handleBack() }}
                            />
                        )}
                        {(showModal && noHayMedicos) && (
                            <Modal
                                title="NO HAY PROFESIONALES"
                                description="Lo sentimos :( No hay profesionales de la salud en tu ciudad especialistas en lo que pediste, te recomendamos tomar tu turno en forma virtual con un profesional de otra ciudad."
                                onClose={() => { handleCloseModal(); crearE({ id_tipo: 45, id_resultado: 2, ip_user: ip, id_form: 6, id_campo_llego: campoLlego }, token); handleBack() }}
                            />
                        )}
                    </div>
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )
            }
        </>
    );
}

export { SolicitudTurnoPaciente };