import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFecha } from '../../hooks/useFecha';
import { SessionExpired } from '../../../components/SessionExpired/SessionExpired';
import logoPTM from "../../../../images/newLogoPTM.jpeg"
import logoMacro from "../../../../images/logo_macro_bma.png"
import logoSuperVielle from "../../../../images/logo_supervielle.png"
import logoBBVA from "../../../../images/logo_bbva.png"
import { pagos1Services } from '../Screen1/services/pagos1.service';
import useIp from '../../../PantallaInicial/components/IniciarSesion/hooks/useIp';
import { useEvento } from '../../../../hooks/useEvento';

const InfoTransferencia = ({
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
}) => {
    const navigate = useNavigate();
    const { fechaString, hora } = useFecha();
    const { id } = useParams();
    const [tokenVencido, setTokenVencido] = useState(false)
    const [precioDRFY, setPrecioDRFY] = useState(0)

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()


    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerPrecio = async () => {
        const precio = await pagos1Services.getPrecioDrFy(token)
        if (precio.error !== undefined) {
            if (precio.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
        }

        setPrecioDRFY(precio)
    }

    useEffect(() => {
        traerPrecio()
    }, [usuarioEnSesion])

    const handleBack = () => {
        navigate(-1)
        crearE({ id_tipo: 64, id_resultado: 1, ip_user: ip }, token)
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="row">
                        <div className='col s12 center'>
                            <div className="logo-container center">
                                <img src={logoPTM} alt="Logo PTM" width="500" />
                            </div>
                            <h5 id="header-form-registro">TRANSFERENCIA BANCARIA</h5>
                        </div>

                        <div className='col s12 center'>
                            <h5>Desde tu HomeBanking podes transferir el importe de {precioDRFY ? `$${precioDRFY.FeeUso + precioDRFY.Honorarios_Prof} ` : ''} a cualquiera de las cuentas cuyos datos te damos a continuación </h5>
                        </div>

                        <div className='col s12 center'>
                            <div className="col s12 m6 l4">
                                <div className="card">
                                    <div className='card-image' style={{ width: "25%" }}>
                                        <img src={logoMacro} alt="Logo Macro" height={105} />
                                    </div>
                                    <div className="card-content" style={{ padding: "0 0 24px 0" }}>
                                        <span className="card-title">Banco: Macro BMA</span>
                                        <p>Alias: JVIRINNIITAUCC</p>
                                        <p>Titular: VIRINNI JORGE EDUARDO</p>
                                        <p>CUIT: 20-12873707-4</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 m6 l4">
                                <div className="card">
                                    <div className='card-image' style={{ width: "25%" }}>
                                        <img src={logoBBVA} alt="Logo BBVA" height={105} />
                                    </div>
                                    <div className="card-content" style={{ padding: "0 0 24px 0" }}>
                                        <span className="card-title">Banco: BBVA</span>
                                        <p>Alias: PORTÓN.AVISO.FINCA</p>
                                        <p>Titular: VIRINNI JORGE EDUARDO</p>
                                        <p>CUIT: 20-12873707-4</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 m6 l4">
                                <div className="card">
                                    <div className='card-image' style={{ width: "25%" }}>
                                        <img src={logoSuperVielle} alt="Logo SuperVielle" height="25%" style={{ marginBottom: "24px" }} />
                                    </div>
                                    <div className="card-content" style={{ padding: "0 0 24px 0" }}>
                                        <span className="card-title">Banco: SuperVielle</span>
                                        <p>Alias: HIJA.FAROL.AVISPA</p>
                                        <p>Titular: VIRINNI JORGE EDUARDO</p>
                                        <p>CUIT: 20-12873707-4</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col s12 center' style={{ marginTop: "12px", marginBottom: "100px" }}>
                            <h5>Una vez que hayas realizado el pago, descargá el comprobante de la transferencia y pulsá: "INFORMAR PAGO"</h5>
                            <button
                                className='btn waves-effect waves-light'
                                type="button"
                                onClick={() => { crearE({ id_tipo: 65, id_resultado: 1, ip_user: ip }, token); navigate(`/datosTransf/${id}`) }}
                                style={{ margin: '4px' }}
                            >
                                INFORMAR PAGO
                            </button>
                            <button
                                className='btn waves-effect waves-light'
                                type="button"
                                onClick={handleBack}
                                style={{ margin: '4px' }}
                            >
                                VOLVER
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    )
}

export { InfoTransferencia }