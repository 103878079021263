import './App.css';

// importar funcionalidades react necesarias
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { UserContextProvider } from './useContexto.js';

// importar componentes 
import { Footer } from './modules/components/Footer/Footer.js';
import { SolicitudTurnoPaciente } from './modules/SolicitudTurno/SolicitudTurnoPaciente/SolicitudTurnoPaciente.js';
import { SolicitudTurnoMedico } from './modules/SolicitudTurno/SolicitudTurnoMedico/SolicitudTurnoMedico.js';
import { IniciarSesion } from './modules/PantallaInicial/components/IniciarSesion/IniciarSesion.js';
import { RecoverPassword } from './modules/PantallaInicial/components/RecuperacionContrasena/RecoverPassword.js';
import { ErrorNotFound } from './modules/components/ErrorNotFound/ErrorNotFound.js';
import { BotonConfiguracionCuenta } from './modules/PantallaInicial/components/BotonConfiguracionCuenta/BotonCongifuracionCuenta.js';
import { BotonNotificaciones } from './modules/PantallaInicial/components/BotonNotificaciones/BotonNotificaciones.js';
import { ConfigsPaciente } from './modules/Configuraciones/Configs/ConfigsPaciente/ConfigsPaciente.js';
import { ConfigsProfesional } from './modules/Configuraciones/Configs/ConfigsProfesional/ConfigsProfesional.js';
import { AsignacionTurno } from './modules/Turnos/AsignacionTurno/AsignacionTurno.js';
import { OfertaTurno } from './modules/Turnos/OfertaTurno/OfertaTurno.js';
import { ConfirmacionTurnoMedico } from './modules/Turnos/ConfirmacionTurno/ConfirmacionTurnoMedico/ConfirmacionTurnoMedico.js';
import { ConfirmacionTurnoPaciente } from './modules/Turnos/ConfirmacionTurno/ConfirmacionTurnoPaciente/ConfirmacionTurnoPaciente.js';
import { PantallaInicial } from './modules/PantallaInicial/components/PantallaInicial/PantallaInicial.js';
import { EsMedicoOPaciente } from './modules/PantallaInicial/components/PantallaInicial/EsMedicoOPaciente.js';
import { Formulario } from './modules/Registro/components/FormularioRegistro/Formulario.js';
import { Bienvenida } from './modules/Bienvenida/Bienvenida.js';
import { GridPaciente } from './modules/Grids/GridPaciente/GridPaciente.js';
import { OfertasTurnoPac } from './modules/Turnos/OfertasTurnoPac/OfertasTurnoPac.js';
import { GridProfesional } from './modules/Grids/GridProfesional/GridProfesional.js';
import { TurnosSinAsignar } from './modules/Turnos/TurnosSinAsignar/TurnosSinAsignar.js';
import { ConsultasProfesional } from './modules/Turnos/ConsultasProfesional/ConsultasProfesional.js';
import { TurnosSinConfirmar } from './modules/Turnos/TurnosSinConfirmar/TurnosSinConfirmar.js';
import { DetalleTurno } from './modules/Turnos/DetalleTurno/DetalleTurno';
import { SolicitudTurnoParaOtro } from './modules/SolicitudTurno/SolicitudTurnoParaOtro/SolicitudTurnoParaOtro';
import { HomeClientes } from './modules/Clientes/HomeClientes/HomeClientes.js';
import { ContactForm } from './modules/Contacto/ContactForm/ContactForm.js';
import { NoDisponible } from './modules/NoDisponible/NoDisponible.js';
import { ConsultasTomadasProfesional } from './modules/Turnos/ConsultasTomadas/ConsultasTomadas.js';
import { BotonPreguntasFrec } from './modules/PantallaInicial/components/BotonPreguntasFrec/BotonPreguntasFrec.js';
import { PreguntasFrecuentesProf } from './modules/PreguntasFrecuentes/PreguntasFrecuentesProf/PreguntasFrecuentesProf.js';
import { PreguntasFrecuentesPac } from './modules/PreguntasFrecuentes/PreguntasFrecuentesPac/PreguntasFrecuentesPac.js';
import { TomaConsulta } from './modules/Turnos/TomaConsulta/TomaConsulta.js';
import { SalaEsperaProf } from './modules/Turnos/SalaEsperaProf/SalaEsperaProf.js';
import { SalaEsperaPac } from './modules/Turnos/SalaEsperaPac/SalaEsperaPac.js';
import { PsicoAdmision } from './modules/Turnos/TomaConsulta/PsicoAdmision/PsicoAdmision.js';
import { ConsultasPaciente } from './modules/Turnos/ConsultasPaciente/ConsultasPaciente.js';
import { ConfirmarRegistro } from './modules/Registro/ConfirmarRegistro/ConfirmarRegistro.js';


// seccion BO
import { Login } from './Backoffice/Login/Login.js';
import { BienvenidaBO } from './Backoffice/Bienvenida/Bienvenida.js';
import { OficinaContable } from './Backoffice/OficinaContable/OficinaContable.js';
import { Facturacion } from './Backoffice/Facturacion/Facturacion.js';
import { Bancos } from './Backoffice/Bancos/Bancos.js';
import { Compras } from './Backoffice/Compras/Compras.js';
import { Pagos } from './Backoffice/Pagos/Pagos.js';
import { GestionNotif } from './modules/PantallaInicial/components/GestionNotif/GestionNotif.js';
import { NotificacionesProf } from './modules/PantallaInicial/components/NotificacionesProf/NotificacionesProf.js';
import { Pagos1 } from './modules/Turnos/Pagos/Screen1/Pagos1.js';
import { Pagos2 } from './modules/Turnos/Pagos/Screen2/Pagos2.js';
import { BotonCapacitaciones } from './modules/PantallaInicial/components/BotonCapacitaciones/BotonCapacitaciones.js';
import { SeleccionMedioPago } from './modules/Turnos/Pagos/SeleccionMedio/SeleccionMedioPago.js';
import { InfoTransferencia } from './modules/Turnos/Pagos/InfoTransferencia/InfoTransferencia.js';
import { RealizarTransferencia } from './modules/Turnos/Pagos/RealizarTransferencia/RealizarTransferencia.js';

// declaracion de variable de entorno de la API:
const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:4000'; // para el proyecto DFY
const apiUrl2 = process.env.REACT_APP_API_URL_2 || 'http://localhost:4000'; // para el proyecto BO

function App() {
  // declarar estados que indican que usuario esta en sesion y si lo esta o no, además de su token de sesión
  const [estaEnSesion, setEstaEnSesion] = useState(false);
  const [usuarioEnSesion, setUsuarioEnSesion] = useState(null);
  const [token, setToken] = useState(null)

  const [estaEnAjustes, setEstaEnAjustes] = useState(false); // estado que indica si el usuario pulso el boton de configuraciones
  const [eligio, setEligio] = useState(false); // aca se almacena si el profesional hizo click en alguno de los 2 botones en la solicitud de turno recibida
  const [estaEnContacto, setEstaEnContacto] = useState(false)
  const [estaEnPregFrec, setEstaEnPregFrec] = useState(false)
  const [estaEnGestionNotif, setEstaEnGestionNotif] = useState(false)
  const [esMedico, setEsMedico] = useState(null) // gestion del registro de un doctor o de un paciente

  // gestion del modal que aparecera en el gameflow de los turnos:
  const [showModal, setShowModal] = useState(false);
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // NOTA: EL LOCALSTORAGE SOLO SE USARA PARA LA GESTION DEL TOKEN, NADA MAS

  return (
    <BrowserRouter>
      <UserContextProvider>
        {/* si el usuario esta en sesion, pero no pulso configuraciones ni esta en una modal, renderizar esos dos botones */}
        {(estaEnSesion && !estaEnAjustes && !showModal && !estaEnContacto && !estaEnPregFrec && !estaEnGestionNotif) && (
          <>
            <BotonConfiguracionCuenta
              usuarioEnSesion={usuarioEnSesion}
              setEstaEnAjustes={setEstaEnAjustes}
            />
            <BotonNotificaciones
              setEstaEnContacto={setEstaEnContacto}
            />
            <BotonPreguntasFrec
              usuarioEnSesion={usuarioEnSesion}
              setEstaEnPregFrec={setEstaEnPregFrec}
              token={token}
            />
            {/* si es profesional:  */}
            {!usuarioEnSesion.usuarioComun &&
              <GestionNotif
                setEstaEnGestionNotif={setEstaEnGestionNotif}
                usuarioEnSesion={usuarioEnSesion}
              />
            }
            {!usuarioEnSesion.usuarioComun &&
              <BotonCapacitaciones
                link="https://www.youtube.com/watch?v=pba5f6G3EjM&list=PLIS6uS3TFnlrTA1X9mQajADdBA1DWXbhR"
                token={token}
              />
            }
            {/* Si es paciente:  */}
            {usuarioEnSesion.usuarioComun &&
              <BotonCapacitaciones
                link="https://www.youtube.com/watch?v=pba5f6G3EjM&list=PLIS6uS3TFnlrTA1X9mQajADdBA1DWXbhR"
                token={token}
              />
            }
          </>
        )}

        {/* las distintas rutas de la aplicacion */}
        {/* PARTE 1: INICIO, REGISTRO, Y SESION */}
        <Routes>
          <Route
            path="/"
            element={
              <PantallaInicial
                setEstaEnContacto={setEstaEnContacto}
              />
            }
          />

          <Route
            path="/registrarse"
            element={
              <EsMedicoOPaciente
                esMedico={esMedico}
                setEsMedico={setEsMedico}
              />
            }
          />

          {/* <Route
            path='/formRegistro'
            element={
              <Formulario
                esMedico={esMedico}
                setEsMedico={setEsMedico}
              />
            }
          /> */}

          <Route
            path='/medico'
            element={
              <Formulario
                esMedico={true}
                setEsMedico={setEsMedico}
              />
            }
          />

          <Route
            path='/paciente'
            element={
              <Formulario
                esMedico={false}
                setEsMedico={setEsMedico}
              />
            }
          />


          <Route
            path='/confRegistro'
            element={
              <ConfirmarRegistro
                setEsMedico={setEsMedico}
              />
            }
          />

          <Route
            path='/inicioSesion'
            element={
              <IniciarSesion
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                apiUrl={apiUrl}
                setToken={setToken}
              />
            }
          />

          <Route
            path='/recuperarContrasena'
            element={
              <RecoverPassword
                apiUrl={apiUrl}
              />
            }
          />

          <Route
            path='/bienvenida'
            element={
              <Bienvenida
                apiUrl={apiUrl}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />


          {/* PARTE 2: CONFIGURAIONES DE LA CUENTA */}
          <Route
            path='/configuracionPaciente'
            element={
              <ConfigsPaciente
                apiUrl={apiUrl}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          <Route
            path='/configuracionMedico'
            element={
              <ConfigsProfesional
                apiUrl={apiUrl}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* PARTE 3: GAMEFLOW DE SOLICITUD, ASIGNACION, Y CONFIRMACION DE UN TURNO */}
          {/* MOSTRAR LOS TURNOS DE UN PACIENTE SIN IMPORTAR SU ESTADO. CON TOKEN */}
          <Route
            path='/misTurnosPac'
            element={
              <GridPaciente
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* MOSTRAR LOS TURNOS DE UN PACIENTE, PERO SOLO AQUELLOS EN ESTADO ASIGNADO (7), 
          PARA QUE EL PACIENTE O LOS ACEPTE O LOS RECHACE. CON TOKEN */}
          <Route
            path='/misOfertasTurnoPac'
            element={
              <OfertasTurnoPac
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* MOSTRAR LOS TURNOS QUE LE LLEGARON A UN PROFESIONAL (ESTADO NUMERO 1), QUE NO LOS TOMO NINGUN OTRO PROFESIONAL, 
          Y QUE LES PERMITA ACEPTARLOS/RECHAZARLOS, Y SI LOS ACEPTA, QUE LES PERMITA ASIGNARLES UNA 
          FECHA, HORA, Y MODALIDAD. CON TOKEN */}
          <Route
            path='/misTurnosProf'
            element={
              <GridProfesional
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* MOSTRAR LOS TURNOS QUE ACEPTO UN PROFESIONAL (ESTADO NUMERO 3), PERO QUE NO LES HAYA ASIGNADO UNA 
          FECHA, HORA, Y MODALIDAD. CON TOKEN  */}
          <Route
            path='/misTurnosAceptadosProf'
            element={
              <TurnosSinAsignar
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* MOSTRAR LOS TURNOS DE UN PROFESIONAL, QUE HAYAN SIDO OFRECIDOS AL PACIENTE Y 
          QUE EL PACIENTE HAYA ACEPTADO (ESTADO NUMERO 4), PARA QUE EL PROFESIONAL PUEDA CONFIRMARLOS. CON TOKEN */}
          <Route
            path='/turnosAConfirmarProf'
            element={
              <TurnosSinConfirmar
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* MOSTRAR LOS TURNOS DE UN PROFESIONAL QUE HAYAN SIDO CONIRMADOS POR AMBAR PARTES (ESTADO NUMEERO 6 --> 
            CONFIRMADO POR PACIENTE). CON TOKEN*/}
          <Route
            path='/misTurnosConfirmadosProf'
            element={
              <ConsultasProfesional
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* MOSTRAR LOS TURNOS DE UN PACIENTE QUE YA HAYAN SIDO CONFIRMADOS POR AMBAS PARTES (ESTADO NUMERO 6 -->
            CONFIRMADO POR PACIENTE). CON TOKEN  */}
          <Route
            path='/misTurnosConfirmadosPac'
            element={
              <ConsultasPaciente
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* MOSTRAR LOS TURNOS DE UN PROFESIONAL QUE YA HAYAN SIDO ATENDIDOS POR EL MISMO. CON TOKEN */}
          <Route
            path='/misConsultasTomadasProf'
            element={
              <ConsultasTomadasProfesional
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* QUE EL PACIENTE PUEDA SOLICITAR UN NUEVO TURNO. CON TOKEN */}
          <Route
            path="/solicitudTurnoPac"
            element={
              <SolicitudTurnoPaciente
                apiUrl={apiUrl}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          {/* CON TOKEN */}
          <Route
            path="/solicitudTurnoPacParaOtro"
            element={
              <SolicitudTurnoParaOtro
                apiUrl={apiUrl}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          {/* QUE EL PROFESIONL PUEDA VER LA INFORMAACION DE UN TURNO, ACEPTARLO O RECHAZARLO, Y 
          SI LO ACEPTA QUE PUEDA ASIGNARLE UNA FECHA, HORA, Y MODALIDAD 
          (ESTO EN LA PANTALLA DE ASIGNARTURNO/:ID). CON TOKEN */}
          <Route
            path="/solicitudTurnoProf/:id"
            element={
              <SolicitudTurnoMedico
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                eligio={eligio}
                setEligio={setEligio}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          {/* QUE EL PROFESIONAL QUE ACEPTO EL TURNO SOLICITADO (ESTADO NUMERO 3), LE PUEDA ASIGNAR FECHA, 
          HORA, MODALIDAD, Y LUGAR DE ATENCION. CON TOKEN  */}
          <Route
            path='/asignacionTurno/:id'
            element={
              <AsignacionTurno
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                setEligio={setEligio}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          {/* QUE EL PACIENTE PUEDA ACPETAR/RECHAZAR LA OFERTA DE TURNO PROPUESTA POR EL MEDICO 
          (ESTADO ASIGNADO --> 7). CON TOKEN */}
          <Route
            path='/ofertaTurno/:id'
            element={
              <OfertaTurno
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          <Route
            path='/pagos1/:id'
            element={
              <Pagos1
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          <Route
            path='/pagos11/:id'
            element={
              <SeleccionMedioPago
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* NOTA: DEBIDO A QUE SOY UN MOGOLICO Y NO SE HACER COSAS ESCALABLES QUEDO ASI: PAGOS/1/IDTURNO
          Y PAGOS/2/idMedio/idTurno
          */}
          <Route
            path='/pagos/1/:id'
            element={
              <InfoTransferencia
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          <Route
            path='/datosTransf/:id'
            element={
              <RealizarTransferencia
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          <Route
            path='/pagos/2/:idMedio/:idTurno'
            element={
              <Pagos2
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          {/* QUE EL PROFESIONAL PUEDA CONFIRMAR TURNOS QUE FUERON ACEPTADOS POR EL PACIENTE (ESTADO NUMERO 4). CON TOKEN  */}
          <Route
            path='confirmacionTurnoProf/:id'
            element={
              <ConfirmacionTurnoMedico
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          {/* QUE EL PACIENTE PUEDA CONFRMAR UN TURNO, QUE YA CONFIRMO EL MEDICO (ESTADO NUMERO 5). CON TOKEN */}
          <Route
            path='/confirmacionTurnoPac/:id'
            element={
              <ConfirmacionTurnoPaciente
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          {/* tanto el paciente como el medico pueden consultar la totalidad de la información 
          de un turno especifico. CON TOKEN */}
          <Route
            path='/detalleTurno/:id'
            element={
              <DetalleTurno
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* pantalla del profesional que la usara cuando este atendiendo al paciente, ingresando datos como
          las practicas a realizar, los medicamentos, etc */}
          <Route
            path='/tomaConsulta/:id'
            element={
              <TomaConsulta
                apiUrl={apiUrl}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          <Route
            path='/tomaConsultaPsicoAdmin/:id'
            element={
              <PsicoAdmision
                apiUrl={apiUrl}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          {/* PANTALLA DE SALA DE ESPERA DEL PROFESIONAL. CON TOKEN */}
          <Route
            path="/salaEsperaProf"
            element={
              <SalaEsperaProf
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* PANTALLA DE SALA DE ESPERA DEL USUARIO/PACIENTE. CON TOKEN */}
          <Route
            path="/salaEsperaPac"
            element={
              <SalaEsperaPac
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* PARTE 5: MODULO DE CLIENTES. ESTA POR AHORA, NO TOKEN */}
          <Route
            path='/clientes'
            element={
              <HomeClientes />
            }
          />

          {/* PARTE 6: MODULO DE CONTACTO, 19-02-2024 TOKEN HECHO */}
          <Route
            path='/contacto'
            element={
              <ContactForm
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
                setEstaEnAjustes={setEstaEnAjustes}
                setEstaEnContacto={setEstaEnContacto}
                setEstaEnPregFrec={setEstaEnPregFrec}
                showModal={showModal}
                handleOpenModal={handleOpenModal}
                handleCloseModal={handleCloseModal}
              />
            }
          />

          {/* PARTE 7: PANTALLA DE FUNCIONALIDAD NO DISPONIBLE. NO TOKEN */}
          <Route
            path='/noDisp'
            element={
              <NoDisponible />
            }
          />

          {/* PARTE 8: PREGUNTAS FRECUENTES. ESTA, NO TOKEN */}
          <Route
            path='/preguntasFrecuentesProf'
            element={
              <PreguntasFrecuentesProf
                setEstaEnPregFrec={setEstaEnPregFrec}
                token={token}
              />
            }
          />

          <Route
            path='/preguntasFrecuentesPac'
            element={
              <PreguntasFrecuentesPac
                setEstaEnPregFrec={setEstaEnPregFrec}
              />
            }
          />

          {/* PARTE 9: Gesionar noificaciones (solo aplica a profesionales)  */}
          <Route
            path='/gestionarNotif'
            element={
              <NotificacionesProf
                setEstaEnGestionNotif={setEstaEnGestionNotif}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setToken={setToken}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setEstaEnSesion={setEstaEnSesion}
              />
            }
          />

          {/* PARTE 10: BACKOFFICE */}
          <Route
            path='/loginBO'
            element={
              <Login
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                apiUrl={apiUrl2}
                setToken={setToken}
              />
            }
          />

          <Route
            path='/bienvenida/BO'
            element={
              <BienvenidaBO
                apiUrl={apiUrl2}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
              />
            }
          />

          <Route
            path='/oficinaContable/BO'
            element={
              <OficinaContable
                apiUrl={apiUrl2}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
              />
            }
          />

          <Route
            path='/facturacion/BO'
            element={
              <Facturacion
                apiUrl={apiUrl2}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
              />
            }
          />

          <Route
            path='/bancos/BO'
            element={
              <Bancos
                apiUrl={apiUrl2}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
              />
            }
          />

          <Route
            path='/compras/BO'
            element={
              <Compras
                apiUrl={apiUrl2}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
              />
            }
          />

          <Route
            path='/pagos/BO'
            element={
              <Pagos
                apiUrl={apiUrl2}
                usuarioEnSesion={usuarioEnSesion}
                token={token}
                setEstaEnSesion={setEstaEnSesion}
                setUsuarioEnSesion={setUsuarioEnSesion}
                setToken={setToken}
              />
            }
          />

          {/* PARTE 4: RUTAS ERRONEAS. NO TOKEN */}
          <Route path='*' element={<ErrorNotFound />} />
        </Routes>

        {/* pie de pagina de la app es exactamente el mismo para todos los componentes */}
        <Footer />
      </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;
