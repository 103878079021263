// importo hooks y custom hooks necesarios
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios'; // importo libreria axios para enviar los datos al backend
import logoPTM from '../../../images/newLogoPTM.jpeg';
import './SolicitudTurnoMedico.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '../../components/Modal/Modal.js';
import { solicTurnoProfServices } from './services/solicTurnoProf.service.js';
import { SessionExpired } from '../../components/SessionExpired/SessionExpired.js';
import { createEvento } from '../../../services/evento.service.js';
import useIp from '../../PantallaInicial/components/IniciarSesion/hooks/useIp.js';
import { useEvento } from '../../../hooks/useEvento.js';

const SolicitudTurnoMedico = ({
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
    eligio,
    setEligio,
    showModal,
    handleOpenModal,
    handleCloseModal,
}) => {
    const { register, handleSubmit, setValue, reset } = useForm();
    const navigate = useNavigate();

    // estados referidos al parametro id del turno generado por el link (aplica para AsignacionTurno, OfertaTurno, y ambas pantallas de confirmacion de turno)
    const [turnoSolicitado, setTurnoSolicitado] = useState({}); // traer los datos del turno que corresponda con el ID
    const { id } = useParams(); // declarar que va  ahaber un parametro es esta url llamado 'id'

    const [txtSolicitud, setTxtSolicitud] = useState(''); // esto sirve nomas para renderizar por pantalla lo que decidio el profesional
    const [tokenVencido, setTokenVencido] = useState(false)
    const [YAAceptado, setYAAceptado] = useState(false)

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    useEffect(() => {
        if (ip !== null) {
            crearE({ id_tipo: 89, id_resultado: 1, ip_user: ip }, token)
        }
    }, [ip])

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerElTurno = async () => {
        // si entro a solicitudTurnoProf/:id, que me traiga el turno del backend en funcion del id enviado como parametro usando useParams()
        if (window.location.pathname.includes('solicitudTurnoProf')) {
            if (usuarioEnSesion) {
                const resultado = await solicTurnoProfServices.getTurnoById(id, token, 1)
                // si ingreso cualquier otra cosa, sease ejemplo: null, 237645, etc, llevar a la pantalla de error
                if (resultado.error !== undefined) {
                    if (resultado.error === "TOKEN VENCIDO.") {
                        setTokenVencido(true);
                        return;
                    }
                    navigate("*")
                }
                // si existe el ID en la base de datos, setea el turno como lo que haya traido (los datos del turno)
                setTurnoSolicitado(resultado)
            }
        }
    }

    // traer el turno del backend.
    useEffect(() => {
        traerElTurno()
        setEligio(false);
    }, [usuarioEnSesion]);

    // funcion que se ejecuta en cuanto el usuario pulse el boton continuar, esta funcion enviara los nuevos datos
    // del turno al backend y llevara al doctor a la pantalla que corresponda en funcion de lo que decidio
    const onSubmit = async (data) => {
        // si el medico acepto el turno solicitado, entonces que actualice el estado del turno a aceptado por medico,
        // y que ademas, complete con los datos del profesional que acepto el turno.
        // En caso de que no se acepte, que solo actualice el estado del turno a rechazado
        let newData = {};
        let response
        const turno = await solicTurnoProfServices.getTurnoById(id, token, 1);
        if (turno.error === "TOKEN VENCIDO.") {
            setTokenVencido(true);
            return;
        }
        setTokenVencido(false)

        if (turno?.IdEstadoTurno !== 1) {
            setYAAceptado(true)
            handleOpenModal();
            return
        }
        setYAAceptado(false)

        if (data.decision === "solicitud aceptada") {
            newData = {
                IdProfesional: usuarioEnSesion.Id, // que medico acepto
                IdEstadoTurno: 3, // ESTADO: aceptado por medico
                ip: ip
            };
            response = await solicTurnoProfServices.updateTurno(id, newData, token, 1)
            if (response.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
        } else if (data.decision === "solicitud rechazada") {
            newData = {
                IdProfesional: usuarioEnSesion.Id, // que medico rechazo
                IdEstadoTurno: 2, // ESTADO: rechazado por medico
                ip: ip
            };
            response = await solicTurnoProfServices.updateTurno(id, newData, token, 1)
            if (response.error === "TOKEN VENCIDO.") {
                setTokenVencido(true);
                return;
            }
            handleOpenModal();
        }
        newData = {}
        reset(); // reseteo los datos del formulario, que en este caso es solo el de decision. (ver setValue mas arriba)
    };

    // funcion que se ejecuta si el usuario hace click en el boton VOLVER
    const handleBack = () => {
        reset();
        setTxtSolicitud('');
        setEligio(false);
        navigate('/misTurnosProf'); // lo redirige a la grilla de turnos del profesional
        crearE({ id_tipo: 90, id_resultado: 1, ip_user: ip }, token)
    }

    const continuarTurno = () => {
        navigate(`/asignacionTurno/${id}`);
        crearE({ id_tipo: 93, id_resultado: 1, ip_user: ip }, token)
    }

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-registro-container row">
                        <form className="col s12" id="myForm" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row" style={{ marginBottom: 0 }}>
                                <div className='col s12 center'>
                                    <div className="logo-container center">
                                        <img src={logoPTM} alt="Logo PTM" width="500" />
                                    </div>
                                    <h5 id="header-form-registro">SOLICITUD TURNO LADO PROFESIONAL DE LA SALUD</h5>
                                </div>

                                <>
                                    {id !== 0 && turnoSolicitado?.Usuario !== undefined && (
                                        <>
                                            <div className='col s12 center info-solicitud' >
                                                <span className='info_sol'>FECHA: {turnoSolicitado?.Fecha}; HORA: {turnoSolicitado?.Hora}</span>
                                            </div>
                                            <div className='col s12 center info-solicitud'>
                                                <span className='info_sol'>SE HA RECIBIDO UNA SOLICITUD DE TURNO DE: {turnoSolicitado?.Usuario?.Nombre} {turnoSolicitado?.Usuario?.Apellido}</span>
                                            </div>
                                            {/* <div className='col s12 center info-solicitud'>
                                                <span className='info_sol'>OBRA SOCIAL: {turnoSolicitado?.Usuario?.Obras_Sociale ? turnoSolicitado?.Usuario?.Obras_Sociale?.Nombre : 'NO TIENE'} ; PLAN: {turnoSolicitado?.Usuario?.NumerosPlane ? turnoSolicitado?.Usuario?.NumerosPlane?.Nombre : 'NO TIENE'}; NÚMERO DE AFILIADO: {turnoSolicitado?.Usuario?.NumeroAfiliado ? turnoSolicitado?.Usuario?.NumeroAfiliado : 'NO TIENE'}</span>
                                            </div> */}
                                            <div className='col s12 center info-solicitud'>
                                                <span className='info_sol'>MODALIDAD: {turnoSolicitado?.Modalidad}</span>
                                            </div>
                                            {turnoSolicitado?.IdPacAAtender !== null ? (
                                                <>
                                                    <div className='col s12 center info-solicitud'>
                                                        <span className='info_sol'>QUIEN NECESITA LA ATENCION MÉDICA ES: {turnoSolicitado?.PacientesNoUsuario?.Nombre} {turnoSolicitado?.PacientesNoUsuario?.Apellido}</span>
                                                    </div>
                                                    <div className='col s12 center info-solicitud'>
                                                        <span className='info_sol'>OBRA SOCIAL: {turnoSolicitado?.PacientesNoUsuario?.Obras_Sociale ? turnoSolicitado?.PacientesNoUsuario?.Obras_Sociale?.Nombre : 'NO TIENE'} ; PLAN: {turnoSolicitado?.PacientesNoUsuario?.NumerosPlane ? turnoSolicitado?.PacientesNoUsuario?.NumerosPlane?.Nombre : 'NO TIENE'}; NÚMERO DE AFILIADO: {turnoSolicitado?.PacientesNoUsuario?.NumeroAfiliado ? turnoSolicitado?.PacientesNoUsuario?.NumeroAfiliado : 'NO TIENE'}</span>
                                                    </div>
                                                </>
                                            ) : (
                                                <p className='center-align info_sol'  >El paciente que solicito el turno es el que desea atenderse</p>
                                            )}
                                        </>
                                    )}

                                    {eligio === false && (
                                        <div className='col s12 center'>
                                            <div className='pregunta-solicitud-container'>
                                                <button
                                                    type="submit"
                                                    id='aceptaSolicitud'
                                                    className='botonSolicitud'
                                                    onClick={() => { setTxtSolicitud('SOLICITUD ACEPTADA'); setEligio(true); onSubmit({ decision: 'solicitud aceptada' }); }}
                                                    form="myForm"
                                                >
                                                    ACEPTAR
                                                </button>

                                                <button
                                                    type="submit"
                                                    id='rechazaSolicitud'
                                                    className='botonSolicitud'
                                                    onClick={() => { setTxtSolicitud('SOLICITUD RECHAZADA'); setEligio(true); onSubmit({ decision: 'solicitud rechazada' }); }}
                                                    form="myForm"
                                                >
                                                    RECHAZAR
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </>
                            </div>
                        </form>

                        {eligio === true && !YAAceptado && (
                            <>
                                <div className='col s12 center'>
                                    <span id='decision-solicitud'>{txtSolicitud}</span>
                                </div>
                                <div className='col s12 center'>
                                    <button
                                        className='btn waves-effect waves-light'
                                        type='button'
                                        id='btn-solicitud-medico-continuar'
                                        onClick={() => txtSolicitud === "SOLICITUD ACEPTADA" ? continuarTurno() : (() => { handleBack(); crearE({ id_tipo: 93, ip_user: ip, id_resultado: 1 }, token); })()}
                                    >
                                        CONTINUAR
                                    </button>
                                </div>
                            </>
                        )}

                        <div className='col s12 center'>
                            <button
                                className='btn waves-effect waves-light'
                                type="button"
                                onClick={handleBack}
                                style={{ marginBottom: "80px", marginTop: "8px" }}
                            >
                                VOLVER
                            </button>
                        </div>

                        {showModal && (
                            <Modal
                                title="RECHAZASTE EL TURNO"
                                description="Rechazaste este turno."
                                onClose={() => { handleCloseModal(); crearE({ id_tipo: 94, id_resultado: 1, ip_user: ip }, token) }}
                            />
                        )}

                        {showModal && YAAceptado && (
                            <Modal
                                title="EL TURNO YA FUE ACEPTADO POR OTRO PROFESIONAL"
                                description="No pudiste aceptar este turno."
                                onClose={handleCloseModal}
                            />
                        )}
                    </div>
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
}

export { SolicitudTurnoMedico }