import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useFecha } from '../../hooks/useFecha.js';
import axios from 'axios';
import logoPTM from ".././../../../images/newLogoPTM.jpeg";
import "./ConfirmacionTurnoMedico.css";
import { useNavigate, useParams } from 'react-router-dom';
import { useUserContext } from '../../../../useContexto.js';
import { Modal } from '../../../components/Modal/Modal.js';
import { solicTurnoProfServices } from '../../../SolicitudTurno/SolicitudTurnoMedico/services/solicTurnoProf.service.js';
import { SessionExpired } from '../../../components/SessionExpired/SessionExpired.js';
import useIp from '../../../PantallaInicial/components/IniciarSesion/hooks/useIp.js';
import { useEvento } from '../../../../hooks/useEvento.js';


const ConfirmacionTurnoMedico = ({
    usuarioEnSesion,
    token,
    setEstaEnSesion,
    setUsuarioEnSesion,
    setToken,
    setEstaEnAjustes,
    setEstaEnContacto,
    setEstaEnPregFrec,
    showModal,
    handleOpenModal,
    handleCloseModal
}) => {
    const { register, handleSubmit, setValue, reset } = useForm();
    const { fechaString, hora } = useFecha();
    const navigate = useNavigate()

    // estados referidos al parametro id del turno generado por el link (aplica para AsignacionTurno, OfertaTurno, y ambas pantallas de confirmacion de turno)
    const [turnoSolicitado, setTurnoSolicitado] = useState({})
    const { id } = useParams();
    const [confrimoTurno, setConfirmoTurno] = useState(false)
    const [tokenVencido, setTokenVencido] = useState(false)

    const ip = useIp()
    const { handleCampoLlego, campoLlego, crearE } = useEvento()

    // efecto para persistencia de sesion
    useEffect(() => {
        const logguedUserJSON = window.localStorage.getItem("DoctorFy")
        if (logguedUserJSON) {
            const user = JSON.parse(logguedUserJSON);
            setUsuarioEnSesion(user);
            setToken(user.token)
            setEstaEnSesion(true);
        }
    }, [])

    const traerElTurno = async () => {
        if (window.location.pathname.includes('confirmacionTurnoProf')) {
            if (usuarioEnSesion) {
                const resultado = await solicTurnoProfServices.getTurnoById(id, token, 3)
                if (resultado.error !== undefined) {
                    if (resultado.error === "TOKEN VENCIDO.") {
                        setTokenVencido(true);
                        return;
                    }
                    if (resultado.esCero) {
                        navigate("*")
                    }
                }
                setTurnoSolicitado(resultado)
            }
        }
    }
    // si esta en la pantalla de confrimacion de turno (lado profesional),  seteando los datos como lo traido del backend
    useEffect(() => {
        traerElTurno()
    }, [usuarioEnSesion])

    const handleBack = () => {
        navigate('/turnosAConfirmarProf')
        crearE({ id_tipo: 120, id_resultado: 1, ip_user: ip }, token)
    }

    // funcion que se va a ejecuat en cuanto el profesional pulse el boton OK, enviando el nuevo estado del turno al backend
    const onSubmit = async (data) => {
        data.IdEstadoTurno = 5 // ESTADO: turno confirmado por parte del medico
        // funcion que envia el objeto con los datos a actualizar al backend usando la libreria axios.
        // await axios.put(`${apiUrl}/api/plataforma_medica/turnos/actualizar/${turnoSolicitado.IdTurno}`, data);
        const response = await solicTurnoProfServices.updateTurno(turnoSolicitado.IdTurno, data, token, 4)
        if (response.error === "TOKEN VENCIDO.") {
            setTokenVencido(true);
            return;
        }
        setConfirmoTurno(true);
        handleOpenModal();
        reset();
    };

    return (
        <>
            {usuarioEnSesion !== undefined && !tokenVencido ? (
                <>
                    <div className="form-registro-container row">
                        <form className="col s12" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className='col s12 center'>
                                    <div className="logo-container center">
                                        <img src={logoPTM} alt="Logo PTM" width="500" />
                                    </div>
                                    <h5 id="header-form-registro">CONFIRMACION DE TURNO LADO PROFESIONAL </h5>
                                </div>

                                <div className='col s12 center'>
                                    <button
                                        className='btn waves-effect waves-light'
                                        type="button"
                                        onClick={handleBack}
                                        style={{ marginBottom: '24px' }}
                                    >
                                        VOLVER
                                    </button>
                                </div>


                                <div className='col s12'>
                                    <span style={{ fontWeight: "500", display: "block" }} >Fecha: {fechaString}. HORA: {hora}</span>
                                    {turnoSolicitado?.Usuario !== undefined && (
                                        <>
                                            <span style={{ fontWeight: "500", display: "block" }}>
                                                El/La señor(a) {turnoSolicitado?.Usuario?.Nombre}  {turnoSolicitado?.Usuario?.Apellido} ha tomado un
                                                turno bajo la modalidad {turnoSolicitado?.Modalidad} para
                                                el dia {turnoSolicitado?.Fecha} a las {turnoSolicitado?.Hora}
                                            </span>
                                            <span style={{ fontWeight: "500", display: "block" }}>
                                                Motivo de consulta: {turnoSolicitado?.Motivo}
                                            </span>
                                            {turnoSolicitado?.Modalidad === "Presencial" && (
                                                <span style={{ fontWeight: "500", display: "block" }}>
                                                    En el lugar de atención: {turnoSolicitado?.AtencionLugare.Nombre}
                                                </span>
                                            )}
                                            {/* <span style={{ fontWeight: "500", display: "block" }}>DATOS DE COBERTURA DEL SOLICITANTE: </span>
                                            <ul>
                                                <li>
                                                    <span style={{ fontWeight: "500", display: "block" }}> * OBRA SOCIAL: {turnoSolicitado?.Usuario?.Obras_Sociale !== null ? (<span>{turnoSolicitado?.Usuario?.Obras_Sociale?.Nombre}</span>) : (<span>NO TIENE</span>)}</span>
                                                </li>
                                                <li>
                                                    <span style={{ fontWeight: "500", display: "block" }}> * EMPRESA DE MEDICINA PREPAGA: {turnoSolicitado?.Usuario?.Empresas_Prepaga !== null ? (<span>{turnoSolicitado?.Usuario?.Empresas_Prepaga?.Nombre}</span>) : (<span>NO TIENE</span>)}</span>
                                                </li>
                                                <li>
                                                    <span style={{ fontWeight: "500", display: "block" }}> * NÚMERO DE PLAN: {turnoSolicitado?.Usuario?.NumerosPlane !== null ? (<span>{turnoSolicitado?.Usuario?.NumerosPlane?.Nombre}</span>) : (<span>NO TIENE</span>)}</span>
                                                </li>
                                                <li>
                                                    <span style={{ fontWeight: "500", display: "block" }}> * NÚMERO DE AFILIADO: {turnoSolicitado?.Usuario?.NumeroAfiliado !== null ? (<span>{turnoSolicitado?.Usuario?.NumeroAfiliado}</span>) : (<span>NO TIENE</span>)}</span>
                                                </li>
                                            </ul> */}
                                        </>
                                    )}
                                    {(turnoSolicitado?.PacientesNoUsuario !== undefined && turnoSolicitado?.PacientesNoUsuario !== null) ? (
                                        <>
                                            <br></br>
                                            <span style={{ fontWeight: "500", display: "block" }}>El paciente que requiere atenderse es: {turnoSolicitado?.PacientesNoUsuario?.Nombre} {turnoSolicitado?.PacientesNoUsuario?.Apellido}</span>
                                            <span style={{ fontWeight: "500", display: "block" }}>Con DNI: {turnoSolicitado?.PacientesNoUsuario?.DniPaciente}</span>
                                            {/* <span style={{ fontWeight: "500", display: "block" }}>DATOS DE COBERTURA DEL PACIENTE: </span>
                                            <ul>
                                                <li>
                                                    <span style={{ fontWeight: "500", display: "block" }}> * OBRA SOCIAL: {turnoSolicitado?.PacientesNoUsuario?.Obras_Sociale !== null ? (<span>{turnoSolicitado?.PacientesNoUsuario?.Obras_Sociale?.Nombre}</span>) : (<span>NO TIENE</span>)}</span>
                                                </li>
                                                <li>
                                                    <span style={{ fontWeight: "500", display: "block" }}> * EMPRESA DE MEDICINA PREPAGA: {turnoSolicitado?.PacientesNoUsuario?.Empresas_Prepaga !== null ? (<span>{turnoSolicitado?.PacientesNoUsuario?.Empresas_Prepaga?.Nombre}</span>) : (<span>NO TIENE</span>)}</span>
                                                </li>
                                                <li>
                                                    <span style={{ fontWeight: "500", display: "block" }}> * NÚMERO DE PLAN: {turnoSolicitado?.PacientesNoUsuario?.NumerosPlane !== null ? (<span>{turnoSolicitado?.PacientesNoUsuario?.NumerosPlane?.Nombre}</span>) : (<span>NO TIENE</span>)}</span>
                                                </li>
                                                <li>
                                                    <span style={{ fontWeight: "500", display: "block" }}> * NÚMERO DE AFILIADO: {turnoSolicitado?.PacientesNoUsuario?.NumeroAfiliado !== null ? (<span>{turnoSolicitado?.PacientesNoUsuario?.NumeroAfiliado}</span>) : (<span>NO TIENE</span>)}</span>
                                                </li>
                                            </ul> */}
                                        </>
                                    ) : (
                                        <span style={{ fontWeight: "500", display: "block" }}>El paciente que solicito el turno es el que requiere atenderse</span>
                                    )}
                                </div>
                                <div className='col s12 center'>
                                    <button type="submit" className='btn waves-effect waves-light' style={{ marginBottom: "80px" }} disabled={confrimoTurno}>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </form>
                        {showModal && (
                            <Modal
                                title="CONFIRMASTE EL TURNO"
                                description="Tu confirmacion de turno ya le llegó al paciente, esperá a que el confirme tambien"
                                onClose={() => { handleCloseModal(); crearE({ id_tipo: 122, id_resultado: 1, ip_user: ip }, token); navigate('/turnosAConfirmarProf') }}
                            />
                        )}
                    </div>
                </>
            ) : (
                <SessionExpired
                    setEstaEnSesion={setEstaEnSesion}
                    setToken={setToken}
                    setUsuarioEnSesion={setUsuarioEnSesion}
                    setEstaEnAjustes={setEstaEnAjustes}
                    setEstaEnPregFrec={setEstaEnPregFrec}
                    setEstaEnContacto={setEstaEnContacto}
                />
            )}
        </>
    );
};

export { ConfirmacionTurnoMedico }